<template>
  <img v-if="image" :src="image" :class="size">
  <img v-else-if="adult" src="../../assets/icons/employee-torso.png" :class="size" style="opacity: 0.15;">
  <img v-else src="../../assets/icons/child-torso.png" :class="size" style="opacity: 0.15;">
</template>

<script>

  import BinaryService from '../../services/BinaryService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import Vue from 'vue';

  export default {
    name: 'ImageView',

    props: {
      imageId: {
        required: true
      },
      size: {
        required: true
      },
      adult: {
        required: false
      },
    },

    watch: {
      imageId() {
        this.updateImage(this.imageId);
      },
    },

    mounted() {
      this.updateImage(this.imageId);
    },

    data: () => ({
      image: null,
    }),

    methods: {
      updateImage(id) {
        if (id) {
          let image = BinaryService.getImage(id, false);
          if (image) {
            this.image = image.img;
            return;
          }

          BinaryService.loadImage(id)
            .then(response => {
              this.createImageFromBlob(response.data, id);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden des Bildes mit ID ' + id);
            })
        } else {
          this.image = null;
        }
      },

      createImageFromBlob(image, id) {
        let reader = new FileReader();
        reader.addEventListener('load', () => {
          this.image = reader.result;
          BinaryService.storeImage(id, {img: this.image, lastLoad: Vue.moment()});
        }, false);
        if (image) {
          reader.readAsDataURL(image);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .micro {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    object-fit: cover;
  }

  .tiny {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
  }

  .small {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
  }

  .medium {
    width: 200px;
    border-radius: 18px
  }

  .large {
    width: 250px;
    border-radius: 18px
  }

  .max {
    width: 100%;
    border-radius: 50px;
    object-fit: cover;
  }
</style>