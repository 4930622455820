<template>
  <div v-if="bankAccount">
    <div v-if="raw">
      <div v-if="bankAccount.accountOwner">{{bankAccount.accountOwner}}</div>
      <div v-if="bankAccount.bankname">{{bankAccount.bankname}}</div>
      <div v-if="bankAccount.iban">IBAN: {{formatIban(bankAccount.iban)}}</div>
      <div v-if="bankAccount.bic">BIC: {{bankAccount.bic}}</div>
    </div>
    <div v-else>
      <p><span v-if="bankAccount.accountOwner">{{bankAccount.accountOwner}}</span></p>
      <p><span v-if="bankAccount.bankname">{{bankAccount.bankname}}</span></p>
      <p><span v-if="bankAccount.iban">IBAN: {{formatIban(bankAccount.iban)}}</span></p>
      <p><span v-if="bankAccount.bic">BIC: {{bankAccount.bic}}</span></p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BankAccountView',

    props: {
      bankAccount: {
        required: true
      },
      raw: {
        required: false
      }
    },

    methods: {
      formatIban(iban) {
        let formattedIban = '';
        let ibanRest = iban;
        while (ibanRest.length > 4) {
          formattedIban += ibanRest.slice(0, 4) + ' ';
          ibanRest = ibanRest.slice(4);
        }
        formattedIban += ibanRest;

        return formattedIban;
      }
    },
  }
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
  }

  span {
    color: cornflowerblue;
  }
</style>