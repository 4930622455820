import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'ChildService',

  getAllChildren() {
    return HttpInterceptor.httpClient().get('child/all');
  },

  getAllOrganizationChildren() {
    return HttpInterceptor.httpClient().get('child/organization/all');
  },

  getAllArchivedOrganizationChildren() {
    return HttpInterceptor.httpClient().get('child/organization/archived/all');
  },

  getAllOrganizationFacilityChildren(referenceNumber) {
    return HttpInterceptor.httpClient().get('child/organization/facility/' + referenceNumber);
  },

  getAllArchivedOrganizationFacilityChildren(referenceNumber) {
    return HttpInterceptor.httpClient().get('child/organization/facility/' + referenceNumber + '/archived');
  },

  deleteChild(childId) {
    return HttpInterceptor.httpClient().delete('child/' + childId + '/delete');
  },

  exportExcel(exportStructure) {
    return HttpInterceptor.httpClient().put('child/export/excel', exportStructure, {responseType: 'arraybuffer'});
  },

  updateChild(child) {
    return HttpInterceptor.httpClient().put('child/' + child.id + '/update', child);
  },

  toggleChildAbsence(childId, date) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/absence/toggle', date);
  },

  setChildAbsences(absenceCommand) {
    return HttpInterceptor.httpClient().put('child/absences/set', absenceCommand);
  },

  setCoronaCompensation(coronaCompensationBulkCommand) {
    return HttpInterceptor.httpClient().put('child/bulk/beitragsersatz', coronaCompensationBulkCommand);
  },

  getChildAbsence(childId, startDate, endDate) {
    return HttpInterceptor.httpClient().get('child/' + childId + '/absence/' + startDate + '/' + endDate + '/overview');
  },

  deleteImageOfChild(childId) {
    return HttpInterceptor.httpClient().delete('child/' + childId + '/image');
  },

  addBookingTime(childId, bookingTime, templateName, createFee) {
    let query = '';
    if (templateName && templateName.length > 0) {
      query += '?templateName=' + templateName;
    }
    if (createFee) {
      if (query.length > 0) {
        query += '&';
      } else {
        query += '?';
      }
      query += 'createFee=' + createFee;
    }
    return HttpInterceptor.httpClient().put('child/' + childId + '/bookingtime/add' + query, bookingTime);
  },

  deleteBookingTime(childId, refNr) {
    return HttpInterceptor.httpClient().delete('child/' + childId + '/bookingtime/delete/' + refNr);
  },

  updateBookingTime(childId, bookingTime, templateName) {
    let query = '';
    if (templateName && templateName.length > 0) {
      query = '?templateName=' + templateName;
    }
    return HttpInterceptor.httpClient().put('child/' + childId + '/bookingtime/update' + query, bookingTime);
  },

  getPreFilledBookingTime(childId, btTemplateRefNr) {
    return HttpInterceptor.httpClient().get('child/' + childId + '/bookingtime/prefilled?btTemplatRefNr=' + btTemplateRefNr);
  },

  recalculateBookingTime(childId, bookingTime) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/bookingtime/recalc', bookingTime);
  },

  getValidChildFees(childId) {
    return HttpInterceptor.httpClient().get('child/' + childId + '/fees');
  },

  getAggregatedChildFees(childId) {
    return HttpInterceptor.httpClient().get('child/' + childId + '/fees/aggregated');
  },

  getCommunitiesByName(name) {
    return HttpInterceptor.httpClient().put('child/communities', name);
  },

  getTaxCertificate(childId, year) {
    return HttpInterceptor.httpClient().get('child/' + childId + '/' + year + '/taxcertificate',{responseType: 'blob', 'Content-Type': 'application/json'});
  },

  getInvoices4Children(childIds, year, month) {
    return HttpInterceptor.httpClient().put('child/invoices/' + year + '/' + month, childIds, {responseType: 'blob', 'Content-Type': 'application/json'});
  },

  getAllInvoices4Facility(facilityReferenceNo, year, month) {
    return HttpInterceptor.httpClient().put('child/invoices/all/' + facilityReferenceNo + '/' + year + '/' + month, {}, {responseType: 'blob', 'Content-Type': 'application/json'});
  },

  sendInvoices4Children(childIds, year, month) {
    return HttpInterceptor.httpClient().put('child/invoices/email/' + year + '/' + month, childIds, {responseType: 'blob', 'Content-Type': 'application/json'});
  },

  addFee(childId, fee) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/fee/add', fee);
  },

  copyFee(childId, fee) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/fee/copy', fee);
  },

  updateFee(childId, fee) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/fee/update', fee);
  },

  reCalcFee(childId, fee) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/fee/recalc', fee);
  },

  deleteFee(childId, refNr) {
    return HttpInterceptor.httpClient().delete('child/' + childId + '/fee/delete/' + refNr);
  },

  createContractPdf(childId, refNr) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/contract/' + refNr + '/create/pdf');
  },

  addContract(childId, contract) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/contract/add', contract);
  },

  updateContract(childId, contract) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/contract/update', contract);
  },

  deleteContract(childId, refNr) {
    return HttpInterceptor.httpClient().delete('child/' + childId + '/contract/delete/' + refNr);
  },

  changeExitDate(childId, exitDate) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/comfort/modify/exittime', exitDate);
  },

  toggleArchiveState(childId) {
    return HttpInterceptor.httpClient().put('child/' + childId + '/archive/toggle', {});
  },
}