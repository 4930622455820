<template>
  <div v-if="isAuthenticated && organization && !isSage">
    <div v-if="organization.financeModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3>
            <md-icon style="color: black">receipt</md-icon>&nbsp;&nbsp;&nbsp;Bezirksrechnung
          </h3>
        </div>
      </div>

      <div v-if="facilitiesNotExists" class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Bezirksrechnungen"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell medium-6">
          <md-card>
            <md-card-header style="min-height: 570px">
              <div class="grid-x grid-padding-x">
                <div v-if="availableFacilities && availableFacilities.length > 0"
                     class="cell large-6 medium-12 small-12">
                  <md-field>
                    <label>Einrichtung</label>
                    <md-select v-model="selectedFacilityReferenceNumber"
                               :disabled="sending || availableFacilities.length <= 1"
                               @md-selected="onSelectFacility">
                      <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                 :key="facility.referenceNumber">
                        {{ facility.longName }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-6 medium-12 small-12">
                  <md-field v-if="integrativeFacilityChildren && integrativeFacilityChildren.length > 0">
                    <label>Rechnung für Integrativkind</label>
                    <md-select :disabled="sending" id="children" name="children" v-model="pdfTemplateConfig.childId"
                               @md-selected="onSelectChild">
                      <md-option :key="child.id" v-bind:value="child.id"
                                 v-for="child of integrativeFacilityChildren">
                        {{ child.masterdata.fullName }} {{ getHandicaps(child.handicapTypes) }}
                      </md-option>
                    </md-select>
                    <md-tooltip>Kind für die Erstellung der Bezirksrechnung auswählen</md-tooltip>
                  </md-field>
                  <div v-else style="padding-top: 24px;">
                    <span class="md-subheading">Keine Integrativkinder in Einrichtung</span>
                  </div>
                </div>
              </div>
              <div v-if="selectedChild" class="grid-x grid-padding-x">
                <div class="cell">
                  <div v-if="selectedFacility && selectedChild" class="grid-x grid-padding-x">
                    <div class="cell large-6 medium-6 small-12">
                      <div v-if="selectedChild.handicapped">
                        <md-icon class="md-primary">check</md-icon>
                        <span>&nbsp;&nbsp;&nbsp;Behinderung {{ getHandicaps(selectedChild.handicapTypes) }}</span>
                        <br>
                        <md-icon v-if="selectedChild.bezirkFachkraftstunden === 'NO'" class="md-accent">close</md-icon>
                        <md-icon v-else class="md-primary">check</md-icon>
                        <span>&nbsp;&nbsp;&nbsp;<i>({{ labels.bezirkFachkraftstundenType[selectedChild.bezirkFachkraftstunden] }})</i></span>
                      </div>
                      <br>
                    </div>
                    <div class="cell large-6 medium-6 small-12">
                      <ImageView :imageId="selectedChild.imageId" size="tiny"></ImageView>
                      <router-link :to="'/Kinder/'+ selectedChild.id">
                        &nbsp;<span>{{ selectedChild.masterdata.fullName }}</span>
                        <md-tooltip md-direction="bottom" style="font-size: medium;">Daten öffnen für
                          <b>{{ selectedChild.masterdata.fullName }}</b>
                          ({{ labels.divisiontype[selectedChild.childDivisiontype] }})
                        </md-tooltip>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedChild && selectedChild.bezirkFachkraftstunden !== 'NO'" class="grid-x grid-padding-x">
                <div class="cell">
                  <div class="md-layout">
                    <div class="md-layout-item" style="padding-right: 15px;">
                      <md-datepicker id="valid-start-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                                     v-model="startDate" md-immediately :disabled="sending"
                                     :class="getValidationClass('startDate')">
                        <label>Start-Datum</label>
                        <span class="md-error" v-if="!($v.pdfTemplateConfig.startDate.required)">Start-Datum ist notwendig</span>
                      </md-datepicker>
                    </div>
                    <div class="md-layout-item" style="padding-left: 15px;">
                      <md-datepicker id="valid-end-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                                     v-model="endDate" md-immediately :disabled="sending"
                                     :class="getValidationClass('endDate')" @md-opened="onOpenEndDatePicker">
                        <label>End-Datum</label>
                        <span class="md-error"
                              v-if="!($v.pdfTemplateConfig.endDate.required)">End-Datum ist notwendig</span>
                      </md-datepicker>
                    </div>
                  </div>
                </div>
                <div class="cell">
                  <div v-if="selectedFacility && selectedChild.bezirkFachkraftstunden === 'TEN'"
                       class="grid-x grid-padding-x">
                    <div class="cell large-6 medium-6 small-12">
                      <md-field>
                        <label>Bezirk Personenkreis 10 Stunden
                          <md-tooltip v-html="facilitySettingsTooltip"></md-tooltip>
                        </label>
                        <md-input v-model="selectedFacility.facilitySettings.bezirkPersonenkreis" disabled/>
                      </md-field>
                    </div>
                    <div class="cell large-6 medium-6 small-12">
                      <md-field>
                        <label>Bezirk Schlüssel 10 Stunden
                          <md-tooltip v-html="facilitySettingsTooltip"></md-tooltip>
                        </label>
                        <md-input v-model="selectedFacility.facilitySettings.bezirkSchluessel" disabled/>
                      </md-field>
                    </div>
                  </div>
                  <div v-if="selectedFacility && selectedChild.bezirkFachkraftstunden === 'FIFTY'"
                       class="grid-x grid-padding-x">
                    <div class="cell large-6 medium-6 small-12">
                      <md-field>
                        <label>Bezirk Personenkreis 50 Stunden
                          <md-tooltip v-html="facilitySettingsTooltip"></md-tooltip>
                        </label>
                        <md-input v-model="selectedFacility.facilitySettings.bezirkPersonenkreis50Stunden" disabled/>
                      </md-field>
                    </div>
                    <div class="cell large-6 medium-6 small-12">
                      <md-field>
                        <label>Bezirk Schlüssel 50 Stunden
                          <md-tooltip v-html="facilitySettingsTooltip"></md-tooltip>
                        </label>
                        <md-input v-model="selectedFacility.facilitySettings.bezirkSchluessel50Stunden" disabled/>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="cell">
                  <div class="grid-x grid-padding-x">
                    <div class="cell large-6 medium-6 small-6">
                      <md-field v-if="selectedChild.bezirkFachkraftstunden === 'TEN'">
                        <label>Pauschale 10 Stunden (in EUR)
                          <md-tooltip v-html="facilitySettingsTooltip"></md-tooltip>
                        </label>
                        <md-input v-model="selectedFacility.facilitySettings.pauschale10Stunden" type="number"
                                  disabled/>
                      </md-field>
                      <md-field v-if="selectedChild.bezirkFachkraftstunden === 'FIFTY'">
                        <label>Pauschale 50 Stunden (in EUR)
                          <md-tooltip v-html="facilitySettingsTooltip"></md-tooltip>
                        </label>
                        <md-input v-model="selectedFacility.facilitySettings.pauschale50Stunden" type="number"
                                  disabled/>
                      </md-field>
                    </div>
                    <div class="cell large-6 medium-6 small-6">
                      <md-field :class="getValidationClass('bezirkTagespauschaleEur')">
                        <label>Tagespauschale (in EUR)</label>
                        <md-input id="bezirkTagespauschaleEur" name="bezirkTagespauschaleEur" autocomplete="off"
                                  type="number"
                                  v-model="pdfTemplateConfig.bezirkTagespauschaleEur" :disabled="sending"/>
                        <span class="md-error" v-if="!($v.pdfTemplateConfig.bezirkTagespauschaleEur.required)">Tagespauschale ist notwendig</span>
                      </md-field>
                    </div>
                  </div>
                </div>
                <div class="cell">
                  <div class="grid-x grid-padding-x">
                    <div class="cell large-6 medium-6 small-12 text-center">
                      <md-field :class="getValidationClass('bezirkZENummer')">
                        <label>ZE-Nummer</label>
                        <md-input id="bezirkZENummer" name="bezirkZENummer" autocomplete="off"
                                  v-model="pdfTemplateConfig.bezirkZENummer" :disabled="sending"/>
                        <span class="md-error" v-if="!($v.pdfTemplateConfig.bezirkZENummer.required)">ZE-Nummer ist notwendig</span>
                      </md-field>
                    </div>
                    <div class="cell large-6 medium-6 small-12 text-center">
                      <br><br>
                      <button class="button success" :disabled="sending || !pdfTemplateConfig.childId || !(isTreasurer || isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                              @click="onCreateDistrictBill">
                        <i class="fi-page-filled"></i>&nbsp;&nbsp;&nbsp;Rechnung erstellen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="selectedChild && selectedChild.bezirkFachkraftstunden === 'NO'">
                <i class="ai-alert-text">Daten unvollständig! Bitte Fachkraftstunden für

                  <router-link :to="'/Kinder/'+ selectedChild.id">
                    &nbsp;<span>{{ selectedChild.masterdata.fullName }}</span>
                    <md-tooltip md-direction="bottom" style="font-size: medium;">Daten öffnen für
                      <b>{{ selectedChild.masterdata.fullName }}</b>
                      ({{ labels.divisiontype[selectedChild.childDivisiontype] }})
                    </md-tooltip>
                  </router-link>

                  angeben.</i>
              </div>
              <div v-if="!selectedChild">
                <h5 class="md-sub-title"><i>Bitte wählen Sie zuerst ein Integrativkind</i></h5>
              </div>
            </md-card-header>
          </md-card>

        </div>

        <div class="cell medium-6">

          <md-card id="districtBillCardId" style="height: 920px;">
            <md-card-header id="districtBillCardHeaderId">
              <div v-if="valid && selectedChild" class="grid-x grid-padding-x">
                <div v-if="pdfLoading" class="cell">
                  <p class="md-sub-title">Erstelle Bezirksrechnung für {{ selectedChild.masterdata.fullName }} ...</p>
                  <md-progress-bar md-mode="indeterminate"></md-progress-bar>
                </div>
                <div v-else class="cell">
                  <p class="md-sub-title">Bezirksrechnung {{ pdfTemplateConfig.startDate | moment("DD.MM.YYYY") }} bis
                    {{ pdfTemplateConfig.endDate | moment("DD.MM.YYYY") }}
                    für {{ selectedChild.masterdata.fullName }}</p>
                  <div class="grid-x grid-padding-x">
                    <div class="cell small-12 medium-12 large-6">
                      <button @click="onExportSingleDistrictBill" class="button primary expanded" :disabled="sending">
                        <i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Bezirksrechnung
                      </button>
                      <md-tooltip>
                        Bezirksrechnung als PDF herunterladen
                      </md-tooltip>
                    </div>
                    <div class="cell small-0 medium-0 large-6">
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <h5 class="md-sub-title"><i>Bitte geben Sie zuerst alle Angaben ein</i></h5>
              </div>
            </md-card-header>
            <md-card-content v-if="selectedChild && valid">
              <div v-if="pdfNumPages > 0" class="cell" style="padding-bottom: 1rem;">
                <md-button v-for="i in pdfNumPages" :key="i" :id="i" @click="onSelectPdfPage(i)" class="md-icon-button">
                  <span v-if="pdfPage === i" style="color: cornflowerblue; font-size: 1.2rem;"><b>{{ i }}</b></span>
                  <span v-else>{{ i }}</span>
                  <md-tooltip>Seite {{ i }}</md-tooltip>
                </md-button>
              </div>
              <div :style="pdfVisibile">
                <md-content id="pdfContentContainerId" class="md-scrollbar md-card">
                  <pdf v-if="pdfUrl" :src="pdfUrl" :page="pdfPage" :scale.sync="pdfScale" :resize="false"
                       @numpages="setPdfNumPages" class="pdf-frame"/>
                </md-content>
              </div>
            </md-card-content>
          </md-card>

        </div>
      </div>

    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="FINANCE" moduleFunction="Bezirksrechnungen"></NoModuleCard>
    </div>
  </div>
</template>

<script>
import jQuery from 'jquery';
import {validationMixin} from 'vuelidate';
import Vue from 'vue';
import Spinner from 'vue-simple-spinner';
import BankAccountView from '../components/views/BankAccountView';
import ImageView from '../components/views/ImageView';
import pdf from 'pdfvuer';
import NoFacilityCard from '../components/cards/NoFacilityCard';
import NoModuleCard from '../components/cards/NoModuleCard';
import HttpErrorHandler from '../services/HttpErrorHandler';

import {
  required,
} from 'vuelidate/lib/validators';
import PdfService from "../services/PdfService";
import ChildService from "../services/ChildService";

export default {
  name: 'DistrictBills',
  mixins: [validationMixin],

  components: {
    NoFacilityCard,
    BankAccountView,
    ImageView,
    pdf,
    'vue-simple-spinner': Spinner,
    NoModuleCard,
  },

  created() {
    this.formatStartDate();
    this.formatEndDate();
    this.initDatePickerSyncHandler(true);
  },

  mounted() {
    this.restoreUserSettings();
    this.reloadData();

    window.addEventListener('resize', (event) => {
      this.resizeDialogElements(event);
    }, {passive: true});
  },

  beforeDestroy() {
    this.initDatePickerSyncHandler(false);
    this.cleanPDFs();
    this.pdfUrl = null;
    this.selectedChild = null;
    this.selected = null;
  },

  data() {
    return {
      sending: false,
      selectedYear: Vue.moment().format("YYYY"),

      availableFacilities: [],
      selectedFacilityReferenceNumber: null,
      selectedFacilityName: '',
      selectedFacility: null,

      selectedChild: null,

      children: [],
      integrativeFacilityChildren: [],

      showExportTaxCertificatesDialog: false,

      pdfLoading: false,
      pdfScale: 'page-width',
      pdfPage: 1,
      pdfNumPages: 0,

      pdfUrl: null,
      pdfLinks: [],
      pdfBlobs: [],

      valid: false,

      startDate: null,
      endDate: null,

      pdfTemplateConfig: {
        facilityReference: this.selectedFacilityReferenceNumber,
        childId: null,
        dsgvoPersonId: null,
        year: null,
        month: null,
        startDate: Vue.moment(),
        endDate: null,
        bezirkTagespauschaleEur: 0,
        bezirkTagespauschaleCent: 0,
        bezirkZENummer: '',
        template: {
          type: 'BEZIRK',
          content: '',
          name: '',
          standard: false,
          printMargins: {
            top: 0,
            right: 0,
            left: 0,
            bottom: 0
          },
          forceUseOfLetterPaper: 0,
        },
        preview: false,
      },
      facilitySettingsTooltip: 'Diese Einstellung kann unter <b><i>Verwaltung -> Einrichtungen</i></b> im Abschnitt <b><i>Weitere Einstellungen -> Bezirksrechnung</i></b> angepasst werden.',
    }
  },

  validations: {
    pdfTemplateConfig: {
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      bezirkTagespauschaleEur: {
        required,
      },
      bezirkZENummer: {
        required,
      },
    },
  },

  methods: {

    initDatePickerSyncHandler(init) {
      if (init) {
        setTimeout(() => {
          jQuery('#valid-start-date-dp').children('input').bind('blur', () => {
            this.startDate = jQuery('#valid-start-date-dp').children('input').val();
            this.writeBackDates();
          });
          jQuery('#valid-end-date-dp').children('input').bind('blur', () => {
            this.endDate = jQuery('#valid-end-date-dp').children('input').val();
            this.writeBackDates();
          });
        }, 500);
      } else {
        jQuery('#valid-start-date-dp').children('input').unbind('blur');
        jQuery('#valid-end-date-dp').children('input').unbind('blur');
      }
    },

    formatStartDate() {
      if (this.startDate === null) {
        if (this.pdfTemplateConfig && this.pdfTemplateConfig.startDate) {
          this.startDate = Vue.moment(this.pdfTemplateConfig.startDate).format('DD.MM.YYYY');
        }
      }
    },

    formatEndDate() {
      if (this.endDate === null) {
        if (this.pdfTemplateConfig && this.pdfTemplateConfig.endDate) {
          this.endDate = Vue.moment(this.pdfTemplateConfig.endDate).format('DD.MM.YYYY');
        }
      }
    },

    onOpenEndDatePicker() {
      if ((this.pdfTemplateConfig.startDate && !this.pdfTemplateConfig.endDate) ||
          (this.startDate && !this.endDate)) {
        // this.pdfTemplateConfig.endDate = this.pdfTemplateConfig.startDate;
        this.endDate = Vue.moment(this.startDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY');
      }
    },

    getHandicaps(handicapTypes) {
      let types = '';
      for (let handicapType of handicapTypes) {
        types += this.labels.handicapType[handicapType] + ', ';
      }
      if (types.length > 0) {
        return '(' + types.substr(0, types.length - 2) + ')';
      } else {
        return '(???)'
      }
    },

    getValidationClass(fieldName) {
      let field = this.$v.pdfTemplateConfig[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    writeBackDates() {
      if (this.startDate) {
        this.pdfTemplateConfig.startDate = Vue.moment(this.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        this.pdfTemplateConfig.startDate = null;
      }

      if (this.endDate) {
        this.pdfTemplateConfig.endDate = Vue.moment(this.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        this.pdfTemplateConfig.endDate = null;
      }
    },

    validateContact() {
      this.$v.$touch();
      this.writeBackDates();
      this.invalid = this.$v.$invalid;
    },

    restoreUserSettings() {
      this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
      if (this.selectedFacilityReferenceNumber === '*') {
        this.selectedFacilityReferenceNumber = null;
      }
    },

    reloadData() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {
          if (this.isAdmin || this.isTreasurer) {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
            this.getAllOrganizationChildren();
            this.buildAvailableFacilities();
          } else {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
            this.buildAvailableFacilities();
          }

          clearInterval(reloadIntervalId);
        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },

    getAllOrganizationChildren() {
      this.sending = true;
      ChildService.getAllOrganizationChildren()
          .then(response => {
            this.children = response.data;
            this.sending = false;
            this.filterChildren();
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Kinder');
          })
    },

    filterChildren() {
      if (this.children && this.selectedFacilityReferenceNumber) {
        this.integrativeFacilityChildren = [];
        for (let child of this.children) {
          if (child.facilityReference === this.selectedFacilityReferenceNumber && child.handicapped) {
            this.integrativeFacilityChildren.push(child);
          }
        }

        this.pdfTemplateConfig.childId = null;
        this.selectedChild = null;
      }
    },

    buildAvailableFacilities() {
      this.availableFacilities = this.getAvailableFacilities;
      for (let i = 0; i < this.availableFacilities.length; i++) {
        if (this.availableFacilities[i].referenceNumber === '*') {
          this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
        }
      }

      if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
        this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
        this.selectedFacilityName = this.availableFacilities[0].name;
      }

      this.availableFacilities.forEach(facility => {
        if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
          this.selectedFacility = facility;
        }
      });

      this.initDefaultFacilityValues();
      this.onSelectFacility();
    },

    initDefaultFacilityValues() {
      this.pdfTemplateConfig.bezirkTagespauschaleEur = '0';
      this.pdfTemplateConfig.bezirkZENummer = '';

      if (this.selectedFacility && this.selectedFacility.facilitySettings && this.selectedChild) {
        this.pdfTemplateConfig.bezirkZENummer = this.selectedFacility.facilitySettings.bezirkZENummer;
        if (this.selectedChild.bezirkFachkraftstunden === 'TEN' && this.selectedFacility.facilitySettings.pauschale10Stunden) {
          this.pdfTemplateConfig.bezirkTagespauschaleEur = this.selectedFacility.facilitySettings.pauschale10Stunden;
        }
        if (this.selectedChild.bezirkFachkraftstunden === 'FIFTY' && this.selectedFacility.facilitySettings.pauschale50Stunden) {
          this.pdfTemplateConfig.bezirkTagespauschaleEur = this.selectedFacility.facilitySettings.pauschale50Stunden;
        }
      }
    },

    resizeDialogElements() {
      setTimeout(() => {
        jQuery('#pdfContentContainerId').height(jQuery('#districtBillCardId').height() - jQuery('#districtBillCardHeaderId').height() - 100)
      }, 100);
    },

    onSelectFacility() {
      if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

        localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
            this.selectedFacilityName = facility.name;
            this.selectedFacility = facility;
          }
        }

        this.filterChildren();
      }
    },

    onSelectChild() {
      this.children.forEach(child => {
        if (child.id === this.pdfTemplateConfig.childId) {
          this.selectedChild = child;
          this.valid = false;
          this.cleanPDFs();
          this.pdfNumPages = 0;
        }
      });

      this.initDefaultFacilityValues();
    },

    cleanPDFs() {
      if (this.pdfUrl) {
        this.pdfUrl = window.URL.revokeObjectURL(this.pdfUrl);
        for (let i = 0; i < this.pdfBlobs.length; i++) {
          this.pdfBlobs[i] = null;
        }
        while (this.pdfBlobs.length > 0) {
          this.pdfBlobs.pop();
        }
        this.pdfBlobs = [];
      }

      for (let i = 0; i < this.pdfLinks.length; i++) {
        document.body.removeChild(this.pdfLinks[i]);
        this.pdfLinks[i] = null;
      }
      while (this.pdfLinks.length > 0) {
        this.pdfLinks.pop();
      }
      this.pdfLinks = [];
    },

    onCreateDistrictBill() {
      this.writeBackDates();
      this.$v.$touch();
      this.valid = !this.$v.$invalid;

      if (this.valid) {
        this.pdfTemplateConfig.bezirkTagespauschaleCent = Math.round(this.pdfTemplateConfig.bezirkTagespauschaleEur * 100);
        this.pdfTemplateConfig.year = Vue.moment(this.pdfTemplateConfig.startDate).format('YYYY');
        this.pdfTemplateConfig.month = Vue.moment(this.pdfTemplateConfig.startDate).format('MM');
        this.createDistrictBill();
      }
    },

    createDistrictBill() {
      // Reset pdfUrl in order to ensure pdf viewer refresh
      this.pdfUrl = null;
      this.getTemplateDataForDistrictBill([this.pdfTemplateConfig], this.selectedChild);
    },

    getTemplateDataForDistrictBill(config, child) {
      this.sending = true;
      this.pdfLoading = true;
      PdfService.getTemplateData(config)
          .then(response => {
            this.sending = false;
            this.pdfLoading = false;
            this.createDistrictBillPdfForChild(response.data, child)
          })
          .catch(e => {
            this.sending = false;
            this.pdfLoading = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Template Daten für Einrichtung <b>' + this.selectedFacilityName +
                '</b> zur Erstellung der Bezirksrechnung <b>' + Vue.moment(this.pdfTemplateConfig.startDate).format('DD.MM.YYYY') +
                '</b> bis <b>' + Vue.moment(this.pdfTemplateConfig.endDate).format('DD.MM.YYYY') + '</b>.');
          })
    },

    createDistrictBillPdfForChild(template, child) {
      this.cleanPDFs();
      this.sending = true;
      this.pdfLoading = true;
      PdfService.createPdf(template)
          .then(response => {
            setTimeout(() => {
              this.pdfBlobs.push(new Blob([response.data, 'utf-8'], {type: "application/pdf"}));
              this.pdfUrl = window.URL.createObjectURL(this.pdfBlobs[this.pdfBlobs.length - 1]);

              this.pdfLinks.push(document.createElement('a'));
              const id = this.selectedFacilityName + ' (' + this.selectedFacilityReferenceNumber + ') Bezirksrechnung ' + Vue.moment(this.pdfTemplateConfig.startDate).format('DD.MM.YYYY') +
                  ' bis ' + Vue.moment(this.pdfTemplateConfig.startDate).format('DD.MM.YYYY') + ' ' + child.masterdata.fullName;
              this.pdfLinks[this.pdfLinks.length - 1].href = this.pdfUrl;
              this.pdfLinks[this.pdfLinks.length - 1].setAttribute('download', id + '.pdf');
              this.pdfLinks[this.pdfLinks.length - 1].setAttribute('id', id);
              document.body.appendChild(this.pdfLinks[this.pdfLinks.length - 1]);

              this.sending = false;
              this.pdfLoading = false;

              this.resizeDialogElements();

            }, 250);
          })
          .catch(e => {
            this.sending = false;
            this.pdfLoading = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Steuerbescheinigung für Kind ' + child.masterdata.fullName + ' und Jahr ' + this.selectedYear);
          })
    },

    onExportSingleDistrictBill() {
      if (this.pdfLinks.length > 0) {
        this.sending = true;
        setTimeout(() => {
          this.pdfLinks[this.pdfLinks.length - 1].click();
          this.sending = false;
        }, 250);
      }
    },

    setPdfNumPages(numPages) {
      if (numPages !== this.pdfNumPages) {
        this.pdfPage = 1;
      }
      this.pdfNumPages = numPages;
    },

    onSelectPdfPage(page) {
      this.pdfPage = page;
    },

  },

  computed: {

    facilitiesNotExists() {
      return this.organization && this.organization.facilities && this.organization.facilities.length <= 0;
    },

    pdfVisibile() {
      if (this.pdfLoading || !this.selectedChild || this.pdfUrl === null) {
        return 'display: none;';
      } else {
        return '';
      }
    },

    dataAvailable() {
      return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
          this.user.allowedFacilities.length > 0 &&
          this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isTreasurer() {
      return this.$store.getters.treasurer;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isFacilityAdmin() {
      return this.$store.getters.facilityAdmin;
    },

    isManagement() {
      return this.$store.getters.management;
    },

    canWrite() {
      return this.$store.getters.canWrite;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    user() {
      return this.$store.getters.user;
    },

    organization() {
      return this.$store.getters.organization;
    },

    getAvailableFacilities() {
      return this.$store.getters.availableFacilities;
    },

    labels() {
      return this.$store.getters.labels
    },
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 8px;
  margin-bottom: 8px;
}

.pdf-frame {
  width: 100%;
  margin: 20px auto;
}

.md-content {
  max-width: 100%;
  max-height: 770px;
  overflow: auto;
}

.billing-title {
  color: chocolate;
  font-weight: bold;
  font-size: larger;
}

.md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
  background: darkseagreen;
}

.md-sub-title {
  color: lightseagreen;
  font-weight: 400;
  font-size: 1.25rem;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  max-width: 560px;
  white-space: pre-wrap;
}

.bill-title {
  color: chocolate;
  font-weight: normal;
  font-size: 1.8rem;
}

.bill-sub-title {
  color: cornflowerblue;;
  font-weight: normal;
  font-size: 1.2rem;
}

</style>
